body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-orange {
  background-color: #e04403;
}

.br-orange {
  border-color: #e04403; ;
}

.btn.btn-login:hover {
  opacity: 0.9;
}

.btn-orange {
  background-color: #e04403;
  color: #fff;
}

.footer-images {
  width: 40px;
  height: 40px;
}

@media (max-width: 575px){
  .footer-images {
    width: 30px;
    height: 30px;
  }
}

.form-input:focus {
  border-color: #e04403;
}

.form-group .form-control:focus {
  border-color: #e04403;
}

.footer {
  background-color: #f5f5f5;
  bottom: 0;
  padding: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.footer-background {
  background-color: #f5f5f5 !important;
}

.wrapper {
	min-height: 100%;
	height: auto !important;
	height: 100%;
	margin: 0 auto auto;
}

.fp-button {
  background-color: white;
  border-color: #e04403;
  color: #e04403;
}

.fp-button:hover {
  color: #e04403;
}

.pointer {
  cursor: pointer;
  pointer-events: auto;
}

.text-blue {
  color: #007bff;
}

.text-orange {
  color: #e04403;
}

@media (max-width: 768px){
  body:not(.mini-navbar){
    background-color: #ffffff;
  }
}

